<template>
  <v-container>
    <v-img
      class="mx-auto"
      src="../assets/img/EnConstruccion.jpg"
    ></v-img>
  </v-container>
</template>

<script>
  
  export default {
    name: 'HomeView',

    components: {
  //    HelloWorld,
    },
  }
</script>

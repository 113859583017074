import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import VigenciaView from '../views/VigenciaView.vue'
/*import PreRegistroView from '../views/PreRegistroView.vue'
import RegistroView from '../views/RegistroView.vue'
import RegistroAuxView from '../views/RegistroAuxView.vue'
import RegistroInfView from '../views/RegistroInfView.vue'*/

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/vigencia/:id',
    name: 'vigencias',
    component: VigenciaView
  },/*
  {
    path: '/preregistro',
    name: 'pre-registro',
    component: PreRegistroView
  },
  {
    path: '/registro',
    name: 'registro',
    component: RegistroView
  },
  {
    path: '/registrogeneral',
    name: 'registroGeneral',
    component: RegistroAuxView
  },
  {
    path: '/registroinfantil',
    name: 'registroInfantil',
    component: RegistroInfView
  },*/
  {
    path: '*',
    redirect: { name: 'home' }
    
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

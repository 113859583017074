<template>
  <v-container>
    <v-alert
      type="error"
      v-if="showAlert"
    >{{ textResponse }}</v-alert>
    <div>
      <v-row class="text-center">
        <v-col cols="12" style="display: flex;justify-content: center;">
          <v-progress-circular
            :size="70"
            :width="7"
            color="green"
            indeterminate
            v-if="loading"
          ></v-progress-circular>
            
          <v-card
            class="mx-auto"
            max-width="800"
            elevation="24"
            shaped
            v-else
          >
            <v-row class="text-center">
              <v-col  style="text-align: -webkit-center;">
                <v-img 
                  :src="require('../../assets/img/logo.png')"
                  max-width="150"
                  height="150"
                >
                  <template v-slot:placeholder>
                    <div class="d-flex align-center justify-center fill-height">
                      <v-progress-circular
                        color="grey"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                  </template>
                </v-img>
              </v-col>
            </v-row>
            <v-card-title class="text-center text-h4 text--primary" style="justify-content: center;">Gobierno Espiritual<br>"El Valle de las Bendiciones"</v-card-title>
            <v-card-text>
              <v-row class="text-center">
                <v-col  style="text-align: -webkit-center;">
                  <!--v-img 
                    :src="require('../../assets/img/'+($route.params.id)+'.jpg')"-->
                  <v-img  
                    :src="'/img/'+id_miembro+'.jpg'"
                    max-width="120"
                    height="150"
                    style="border-radius: 25px;box-shadow: 0px 1px 14px 0px;"
                    v-if="id_estatus == 1"
                  >
                    <template v-slot:placeholder>
                      <div class="d-flex align-center justify-center fill-height">
                        <v-progress-circular
                          color="grey"
                          indeterminate
                        ></v-progress-circular>
                      </div>
                    </template>
                  </v-img>
                  <v-img 
                    :src="require('../../assets/img/undefined.jpg')"
                    max-width="120"
                    height="150"
                    style="border-radius: 25px;box-shadow: 0px 1px 14px 0px;"
                    v-else
                  >
                    <template v-slot:placeholder>
                      <div class="d-flex align-center justify-center fill-height">
                        <v-progress-circular
                          color="grey"
                          indeterminate
                        ></v-progress-circular>
                      </div>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
              <br>
              <p class="text-center text-h3 text--primary">{{nombre}}</p>
              <p class="text-center text-h5 text--primary">{{ministerio}}</p>
              <p class="text-center text-h5 text--primary" v-if="id_estatus == 1">Congregación: {{ congregacion }}<br><!--(Pablo Macías Valenzuela #4312 Col. Ruiz Cortinez)--></p>
              <v-alert
                border="top"
                dense
                text
                :type = type
                prominent
              >
                {{des_estatus}}
              </v-alert>
            </v-card-text>
          </v-card>
        </v-col>      
      </v-row>
    </div>
  </v-container>
</template>

<script>
  import axios from "axios";

  export default {
    name: 'VigenciaView',

    data: () => ({
      loading: false,
      show: false,
      urlApi: process.env.VUE_APP_ENV_MIEMBROS_API,
      textResponse: '',
      showAlert: false,
      nombre: 'Sin Información para Mostrar',
      ministerio: '',
      congregacion: '',
      des_estatus: 'NO VIGENTE',
      id_estatus: 0,
      id_miembro: 0
    }),

    created(){
      this.loading = true
      this.getVigencia()
    },

    computed: {
      type (){
        if(this.id_estatus == 1){
          return "success"
        }else{
          return "error"
        }
      }
    },

    methods: {      
      getVigencia () {
        var self = this
        var param = {
          id_credencial: self.$route.params.id
        }

        axios.post(self.urlApi+"vigencia/miembros", param).then((result) => {
          console.log(result)
          if(result.data.success){
            self.nombre = result.data.data.nom_miembro
            self.ministerio = result.data.data.nom_ministerio
            self.congregacion = result.data.data.congregacion
            self.des_estatus = result.data.data.des_estatus.toUpperCase()
            self.id_estatus = result.data.data.id_estatus
            self.id_miembro = result.data.data.id_miembro
          }
          self.loading = false
        }).catch(error=>{
          if(error.response && error.response.status == '404'){
            self.nombre = "No se encontró información"
          }else{
            self.textResponse = "Tuvimos algunos inconvenientes, Favor de intentar mas tarde."
            self.showAlert = true
            setTimeout(function(){
                self.showAlert = false;
            }, 5000);
          }
          self.loading = false
          console.log(error)
        })
      }
    }
  }
</script>

<template>
  <Vigencia />
</template>

<script>
import { defineComponent } from 'vue';

// Components
import Vigencia from '../components/vigencia/Vigencia.vue';

export default defineComponent({
  name: 'VigenciaView',


  components: {
    Vigencia,
  },
});
</script>
